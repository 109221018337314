






































































































































































































































































































import {
  ActiveInnerDetails,
  CourseDetailsTab,
  UpskillCoursesDetails,
  UpskillInnerDetails
} from "@/interfaces/candidate/upskill_courses_details";

import Vue, { PropType } from "vue";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { extract_course_provider_logo } from "@/utils/upskill";
import { format_overall_score, format_overall_score_v2 } from "@/utils/global";
import SkillsChips from "@/components/shared/SkillsChips.vue";

export default Vue.extend({
  name: "UpskillCoursesDetails",
  computed: {
    UpskillInnerDetails() {
      return UpskillInnerDetails;
    },
    CourseDetailsTab() {
      return CourseDetailsTab;
    }
  },
  data(): UpskillCoursesDetails {
    return {
      active_provider: "",
      active_provider_logo: "",
      active_course: "",
      active_tab: CourseDetailsTab.SKILLS_DETAILS,
      active_inner_details:
        UpskillInnerDetails.COURSE_SKILLS as ActiveInnerDetails,
      course_skills: [],
      matched_skills: [],
      selected_course: null
    };
  },

  components: { SkillsChips, DataNotFound },
  props: {
    active_skill_group: {
      type: String,
      required: true
    },
    upskill_data: {
      type: Object as PropType<UpskillCourses.UpskillNewFormat>,
      required: true
    },
    remaining_skill_gap: {
      type: Array,
      required: true
    }
  },
  created() {
    // Setting active course provider
    if (Object.keys(this.upskill_data).length > 0) {
      this.active_provider = Object.keys(this.upskill_data)[0];
      this.active_provider_logo = extract_course_provider_logo(
        this.active_provider
      ).logo;
      this.update_active_course(this.upskill_data[this.active_provider][0]);
    }
  },
  methods: {
    format_overall_score,
    extract_course_provider_logo,
    update_active_tab(number: number) {
      if (!number) this.active_tab = CourseDetailsTab.SKILLS_DETAILS;
      else this.active_tab = CourseDetailsTab.COURSE_DETAILS;
    },
    update_active_provider(provider: string) {
      if (this.active_provider === provider) return;
      this.active_provider = provider;
      this.active_provider_logo = extract_course_provider_logo(
        this.active_provider
      ).logo;
      this.update_active_course(this.upskill_data[this.active_provider][0]);
    },
    update_active_course(course: UpskillCourses.UpskillCourseDetail) {
      this.active_course = course.title;
      this.update_active_tab(0);
      this.active_inner_details = UpskillInnerDetails.COURSE_SKILLS;
      this.course_skills = course.course_skills;
      this.matched_skills = course.matched_skills;
      this.selected_course = course;
    },
    calculate_overall_score(courses: UpskillCourses.UpskillCourseDetail[]) {
      let total = 0;
      courses.forEach((course) => (total += course.score));
      return format_overall_score_v2(total.toString());
    }
  }
});
