























































import Vue from "vue";
import {
  SELECTED_NATIONAL_INSTITUTE,
  UPSKILL_COURSE_PAYLOAD,
  UPSKILL_COURSES_LOADING
} from "@/store/modules/institute/constants";
import { mapActions, mapGetters } from "vuex";
import UpskillCareerLoader from "@/components/candidate/UpskillCareerLoader.vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import { UpskillCourses } from "@/interfaces/modules/institute/upskill_courses";
import { format_overall_score } from "@/utils/global";
import { UPSKILL_COURSES } from "@/store/modules/candidates/constants";
import UpskillCoursesDetails from "@/components/candidate/UpskillCoursesDetails.vue";

export default Vue.extend({
  name: "UpskillCourses",
  components: {
    GoBackHeader,
    UpskillCoursesDetails,
    SkillsGroup,
    UpskillCareerLoader
  },
  // route validator to check national institute is selected for comparison or not
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const national_institute =
        vm.$store.getters[`institute/${SELECTED_NATIONAL_INSTITUTE}`];
      if (!national_institute.national_institute) {
        next("/institute/comparison");
      } else next();
    });
  },
  data(): UpskillCourses {
    return {
      active_skill_group: ""
    };
  },
  computed: {
    ...mapGetters("institute", {
      get_upskill_courses_loading: UPSKILL_COURSES_LOADING,
      upskill_courses: UPSKILL_COURSES,
      get_upskill_courses_payload: UPSKILL_COURSE_PAYLOAD
    })
  },
  async created() {
    await this.fetch_upskill_courses(this.get_upskill_courses_payload);
  },
  methods: {
    ...mapActions("institute", {
      fetch_upskill_courses: UPSKILL_COURSES
    }),
    format_overall_score,
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    }
  }
});
